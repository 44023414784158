const AUTH = {
  authenticate: 'auth/authenticate',
  refresh: 'auth/refresh',
  logout: 'auth/logout',
  workspace: 'auth/workspace',
  google: 'auth/google',
  desktopSignin: 'auth/desktop-signin',
};

const USER = {
  signup: 'user',
  account: 'user/account',
  workspaces: 'user/workspaces',
  workspacesForSuperAdmin: 'user/workspaces-for-superadmin',
  resetPassword: 'user/reset-password',
  changePassword: 'user/change-password',
  updateAddress: 'user/location',
  users: () => 'user/users',
  faceRegister: 'face-recognition/register',
  fetchFaceIdData: 'face-recognition/userData',
};

const ORGANIZATION = {
  create: 'organization',
  organizations: () => 'organization/organizations',
  filterOrganizationAll: () => 'organization/filterOrganizationAll',
};

const TWO_FACTOR = {
  verify: 'twofactor/verify',
  renew: 'twofactor/renew',
};

const WORKSPACE_HOLIDAY = {
  offTimeHolidays: (workspaceId) => `workspace/${workspaceId}/offTimeHolidays`,
  createHoliday: (workspaceId) => `workspace/${workspaceId}/offTimeHoliday`,
  deleteHoliday: (workspaceId, holidayId) => `workspace/${workspaceId}/offTimeHoliday/${holidayId}`,
  editHoliday: (workspaceId, holidayId) => `workspace/${workspaceId}/offTimeHoliday/${holidayId}`,
};

const WORKSPACE = {
  create: 'workspace',
  workspaceUpdateWebcamAuditsPerPeriod: (workspaceId) => `workspace/${workspaceId}/webcamAuditsPerPeriod`,
  workspaceUpdateScreenshotsPerPeriod: (workspaceId) => `workspace/${workspaceId}/screenshotsPerPeriod`,
  workspaceWebcamAudits: (workspaceId) => `workspace/${workspaceId}/webcamAudits`,
  workspaceWebcamViolations: (workspaceId) => `workspace/${workspaceId}/webcamViolations`,
  workspaceLocationViolations: (workspaceId) => `workspace/${workspaceId}/locationViolations`,
  workspaceUpdateBlurScreenshots: (workspaceId) => `workspace/${workspaceId}/blurScreenshots`,
  members: (workspaceId) => `workspace/${workspaceId}/members`,
  webcamAuditLatest: (workspaceId) => `workspace/${workspaceId}/members/webcam/latest`,
  membersActivity: (workspaceId) => `workspace/${workspaceId}/members/activity`,
  membersActivityExport: (workspaceId) => `workspace/${workspaceId}/members/activity/export`,
  membersDailyActivity: (workspaceId) => `workspace/${workspaceId}/members/daily-activity`,
  membersGeolocations: (workspaceId) => `workspace/${workspaceId}/members/geolocations`,
  membersBalances: (workspaceId) => `workspace/${workspaceId}/members/balances`,
  membersCount: (workspaceId) => `workspace/${workspaceId}/members/count`,
  editMembersBalances: (workspaceId) => `workspace/${workspaceId}/members/balances`,
  member: (workspaceId) => `workspace/${workspaceId}/member`,
  editMember: (workspaceId, memberId) => `workspace/${workspaceId}/member/${memberId}`,
  getMember: (workspaceId, memberId) => `workspace/${workspaceId}/member/${memberId}`,
  deleteMember: (workspaceId, memberId) => `workspace/${workspaceId}/member/${memberId}`,
  memberUpdateAccountType: (workspaceId, memberId) => `workspace/${workspaceId}/member/${memberId}/accountType`,
  memberUpdateWebcamAuditsPerPeriod: (workspaceId, memberId) => `workspace/${workspaceId}/member/
    ${memberId}/webcamAuditsPerPeriod`,
  memberUpdateScreenshotsPerPeriod: (workspaceId, memberId) => `workspace/${workspaceId}/member/
    ${memberId}/screenshotsPerPeriod`,
  memberUpdateBlurScreenshots: (workspaceId, memberId) => `workspace/${workspaceId}/member/
    ${memberId}/blurScreenshots`,
  createTeam: (workspaceId) => `workspace/${workspaceId}/team`,
  getAllTeams: () => 'workspace/teams',
  filterTeamAll: () => 'workspace/filterTeamAll',
  getAllUsers: () => 'workspace/members/users',
  getAllWorkspaces: () => 'workspace/workspaces',
  filterWorkspaceAll: () => 'workspace/filterWrokspaceAll',
  deleteTeam: (workspaceId, teamId) => `workspace/${workspaceId}/team/${teamId}`,
  updateTeam: (workspaceId, teamId) => `workspace/${workspaceId}/team/${teamId}`,
  getTeams: (workspaceId) => `workspace/${workspaceId}/teams`,
  resolveLocationViolation: (workspaceId) => `workspace/${workspaceId}/locationViolation/resolve`,
  membersInsiderThreats: (workspaceId) => `workspace/${workspaceId}/insiderThreats`,
  resolveMemberThreats: (workspaceId, memberId) => `workspace/${workspaceId}/members/
    ${memberId}/insiderThreats/resolve`,
  memberInsiderThreats: (workspaceId, memberId) => `workspace/${workspaceId}/members/${memberId}/insiderThreats`,
  resolveThreat: (violationId) => `workspace/insiderThreats/${violationId}/resolve`,
  resendInvitation: (workspaceId, userId) => `workspace/${workspaceId}/user/${userId}/resend-invitation`,
  getTeam: (workspaceId, teamId) => `workspace/${workspaceId}/team/${teamId}`,
  getWorkspacePicture: (workspaceId) => `workspace/${workspaceId}/picture`,
  updateWorkspacePicture: (workspaceId) => `workspace/${workspaceId}/picture`,
  removeWorkspacePicture: (workspaceId) => `workspace/${workspaceId}/picture`,
  authentication: (workspaceId) => `workspace/${workspaceId}/authentication`,
  offTimePolicies: (workspaceId) => `workspace/${workspaceId}/offTimePolicies`,
  editOffTimeBalance: (workspaceId) => `workspace/${workspaceId}/offTimeBalance`,
  createTimeOffPolicy: (workspaceId) => `workspace/${workspaceId}/offTimePolicy`,
  deleteOffTimePolicy: (workspaceId, offTimePolicyId) => (
    `workspace/${workspaceId}/offTimePolicy/${offTimePolicyId}`
  ),
  archiveOffTimePolicy: (workspaceId, offTimePolicyId) => (
    `workspace/${workspaceId}/offTimePolicy/${offTimePolicyId}/archive`
  ),
  restoreOffTimePolicy: (workspaceId, offTimePolicyId) => (
    `workspace/${workspaceId}/offTimePolicy/${offTimePolicyId}/restore`
  ),
  updateOffTimePolicy: (workspaceId, offTimePolicyId) => (
    `workspace/${workspaceId}/offTimePolicy/${offTimePolicyId}`
  ),
  getOffTimeData: (workspaceId, memberId) => `workspace/${workspaceId}/offTimeData/${memberId}`,
  getTeamOffTimeData: (workspaceId) => `workspace/${workspaceId}/teamOffTimeData`,
  createOffTimeRequest: (workspaceId, memberId) => `workspace/${workspaceId}/createOffTimeRequest/${memberId}`,
  editOffTimeRequest: (workspaceId, requestId) => `workspace/${workspaceId}/editOffTimeRequest/${requestId}`,
  deleteOffTimeRequest: (workspaceId, requestId) => `workspace/${workspaceId}/deleteOffTimeRequest/${requestId}`,
  approveOffTimeRequest: (workspaceId, offTimeRequestId) => (
    `workspace/${workspaceId}/offTimeRequest/${offTimeRequestId}/approve`
  ),
  denyOffTimeRequest: (workspaceId, offTimeRequestId) => (
    `workspace/${workspaceId}/offTimeRequest/${offTimeRequestId}/deny`
  ),
  designations: (workspaceId) => (
    `workspace/${workspaceId}/designations`
  ),
  designation: (workspaceId) => (
    `workspace/${workspaceId}/designation`
  ),
  deleteDesignation: (workspaceId, designationId) => (
    `workspace/${workspaceId}/designation/${designationId}`
  ),
  departments: (workspaceId) => (
    `workspace/${workspaceId}/departments`
  ),
  department: (workspaceId) => (
    `workspace/${workspaceId}/department`
  ),
  deleteDepartment: (workspaceId, departmentId) => (
    `workspace/${workspaceId}/department/${departmentId}`
  ),
  getWorkBreaks: (workspaceId) => `workspace/${workspaceId}/workBreaks`,
  archiveWorkBreakPolicy: (workspaceId, workBreakPolicyId) => (
    `workspace/${workspaceId}/workBreakPolicy/${workBreakPolicyId}/archive`
  ),
  restoreWorkBreakPolicy: (workspaceId, workBreakPolicyId) => (
    `workspace/${workspaceId}/workBreakPolicy/${workBreakPolicyId}/restore`
  ),
  deleteWorkBreakPolicy: (workspaceId, workBreakPolicyId) => (
    `workspace/${workspaceId}/workBreakPolicy/${workBreakPolicyId}`
  ),
  createWorkBreaks: (workspaceId) => `workspace/${workspaceId}/workBreaks`,
  updateWorkBreaks: (workspaceId, workBreakPolicyId) => `workspace/${workspaceId}/workBreaks/${workBreakPolicyId}`,
  updateMembership: (workspaceId) => `workspace/${workspaceId}/subscription/membership`,
  getInvoices: (workspaceId) => `workspace/${workspaceId}/subscription/invoices`,
  subscription: (workspaceId) => `workspace/${workspaceId}/subscription`,
  ...WORKSPACE_HOLIDAY,
  membersWorkingHours: (workspaceId) => `workspace/${workspaceId}/members/workingHours`,
  updateWorkingHours: (workspaceId, memberId) => `workspace/${workspaceId}/member/${memberId}/workingHours`,
  updateOrganizationWorkingHours: (workspaceId) => `workspace/${workspaceId}/workingHours`,
  memberships: () => 'membership',
  membership: (id) => `membership/${id}`,
  billingAddress: (workspaceId) => `workspace/${workspaceId}/billingAddress`,
  memberChanges: (workspaceId) => `workspace/${workspaceId}/memberChanges`,
};

const MEMBER = {
  activity: (memberId) => `member/${memberId}/activity`,
  activityList: (memberId) => `member/${memberId}/activity-list`,
  screenshot: (memberId) => `member/${memberId}/file/screenshot`,
  webcamAudit: (memberId) => `member/${memberId}/webcamAudit`,
  webcamAudits: (memberId) => `member/${memberId}/webcamAudits`,
  webcamViolations: (memberId) => `member/${memberId}/webcamViolations`,
  locationViolations: (memberId) => `member/${memberId}/locationViolations`,
  activityLogs: () => 'member/activity-logs',
};

const TEAM = {
  create: 'team',
  emailSchedule: (teamId) => `team/${teamId}/emailSchedule`,
};

const API = {
  AUTH,
  USER,
  ORGANIZATION,
  TWO_FACTOR,
  WORKSPACE,
  MEMBER,
  TEAM,
};

export default API;
