/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useContext, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import './Plans.css';
import PaymentModal from './PaymentModal';
import { sendRequest } from '../../../api/shootAPI';
import API from '../../../api/endpoints';
import { UserContext } from '../../../contexts/User';

function Plans() {
  const { activeWorkspace } = useContext(UserContext);
  const [opened, setOpened] = useState(false);
  const [membership, setMembership] = useState(activeWorkspace.membership || 'None');
  const [membersCount, setMembersCount] = useState(0);
  const [tableHeader, setTableHeader] = useState([]);
  const [tableBody, setTableBody] = useState([]);

  // Transform the data
  const transformData = (plans) => {
    // TableHeader Transformation
    const TableHeader = plans.map((plan) => ({
      plan: plan.name,
      description: plan.description,
      price: plan.price,
      recommended: plan.recommended,
    }));

    // TableBody Transformation
    const features = [
      'Desktop Time Tracking App',
      'Members',
      'Timesheets',
      'Screenshot Frequency',
      'Webcam Audit',
      'Team Engagement',
      'Geo Dashboard',
      'Insider Threats',
      'Settings & Permissions',
      'Time Off Requests',
      'Scheduled Email',
      'Two-factor Authentication',
      'Help Center',
      'Chat Support',
      'Email Support SLA (24x5)',
    ];

    const TableBody = features.map((feature) => {
      const row = { feature };
      plans.forEach((plan) => {
        // eslint-disable-next-line no-nested-ternary
        row[plan.name.toLowerCase()] = plan[feature] === true ? 'Included'
          : plan[feature] === false ? 'NA'
            : plan[feature];
      });
      return row;
    });

    return { TableHeader, TableBody };
  };

  const closeModal = () => {
    setOpened(false);
  };

  const getMembersCount = async () => {
    const res = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.membersCount(activeWorkspace.id),
    });

    setMembersCount(res.data.membersCount);
  };

  const getMemberships = async () => {
    const res = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.memberships(),
    });

    // Transform and log the result
    const { TableHeader, TableBody } = transformData(res.data);
    setTableHeader(TableHeader);
    setTableBody(TableBody);
  };

  const openModal = (membership) => {
    setMembership(membership);
    setOpened(true);
  };

  useEffect(() => {
    getMembersCount();
    getMemberships();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {tableHeader.length && tableBody.length ? (
        <table className="table plans-table">
          <thead>
            <tr>
              <th scope="col">
                <h5 className="fw-bold feature">
                  Please choose a plan from here to streamline workflows & productivity
                </h5>
              </th>
              { tableHeader.map((data, i) => (
                <th key={i} scope="col" className="header-title text-center">
                  <div className="d-flex align-items-center justify-content-between">
                    <h6 className="fw-semibold mb-1">{ data.plan }</h6>
                    { data.recommended ? <span className="recommended-badge">Recommended</span> : null }
                  </div>
                  <p className="description fw-normal mb-2">{ data.description }</p>
                  <p className="price-start mb-1">Starts at</p>
                  <p className="d-flex align-items-center">
                    $
                    <span className="price fw-semibold ml-1 mr-1">{ data.price }</span>
                    /month per user
                  </p>
                </th>
              )) }
            </tr>
          </thead>
          <tbody>
            { tableBody.map((data, i) => (
              <tr key={i}>
                <td className="fw-semibold feature">{ data.feature }</td>
                <td>{ data.basic }</td>
                <td>{ data.team }</td>
                <td>{ data.professional }</td>
                <td>{ data.enterprise }</td>
              </tr>
            )) }
            <tr>
              <td />
              { tableHeader.map((data, i) => (
                <td>
                  <Button
                    className={activeWorkspace.membership !== data.plan ? 'btn-primary' : 'btn-second'}
                    variant="link"
                    color="primary"
                    component="span"
                    key={i}
                    disabled={activeWorkspace.membership === data.plan}
                    onClick={() => openModal(data.plan)}
                  >
                    { activeWorkspace.membership === data.plan ? 'Current Plan' : 'Choose Plan' }
                  </Button>
                </td>
              )) }
            </tr>
          </tbody>
        </table>
      ) : null}
      { opened && <PaymentModal closeModal={closeModal} membership={membership} membersCount={membersCount} /> }
    </div>
  );
}

export default Plans;
