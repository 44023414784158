import React from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete, TextField, Chip, Box, FormControl,
} from '@mui/material';

export function ChipSelectSearch({
  label,
  members,
  handleChange,
  selectedMembers,
  className,
  id,
  handleSelectAll,
  placeholder = 'required',
  selectAllBtn = false,
  ...props
}) {
  return (
    <FormControl sx={{ marginBottom: '16px' }} variant="standard" fullWidth>
      {/* Select All Button */}
      {selectAllBtn && (
        <Box
          sx={{
            color: 'var(--green-3, #53A551)',
            textAlign: 'right',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            position: 'absolute',
            right: 0,
            top: 0,
            cursor: 'pointer',
            zIndex: 2,
          }}
          onClick={handleSelectAll}
        >
          Select All
        </Box>
      )}

      {/* Autocomplete component for searchable selection */}
      <Autocomplete
        multiple
        id={id}
        options={members}
        value={selectedMembers}
        onChange={(event, newValue) => {
          handleChange({ target: { name: id, value: newValue } });
        }}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...params}
            label={placeholder}
            variant="standard"
            inputProps={{
              ...params.inputProps,
              'data-testid': 'timezone',
            }}
          />
        )}
        renderTags={(value, getTagProps) => value.map((option, index) => (
          <Chip
            key={index}
            label={option.name}
            size="small"
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...getTagProps({ index })}
            onDelete={() => {
              const filtered = selectedMembers.filter((item) => item !== option);
              handleChange({ target: { name: id, value: filtered } });
            }}
          />
        ))}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...props}
      />
    </FormControl>
  );
}

ChipSelectSearch.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  selectedMembers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  placeholder: PropTypes.string,
  selectAllBtn: PropTypes.bool,
  handleSelectAll: PropTypes.func,
};
