import React from 'react';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  Modal, Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckoutForm from './CheckoutForm';
import CardImage from '../../../images/card.png';
import OrderImage from '../../../images/order.png';

const Card = styled('div')`
  border-radius: 8px;
  border: 1px solid #4062E533;
  background: #F7FAFC;
  height: 100%;
`;

const CardHeader = styled('div')`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid #4062E533;
`;

const CardBody = styled('div')`
  padding: 16px;
`;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function PaymentModal({
  closeModal, membership, membersCount,
}) {
  const getPriceByMembership = (membershipType) => {
    const prices = {
      Basic: 400,
      Team: 700,
      Professional: 1000,
      Enterprise: 2500,
    };

    return prices[membershipType] || 0;
  };
  const options = {
    mode: 'subscription',
    amount: getPriceByMembership(membership) * membersCount,
    currency: 'usd',
    // Fully customizable with appearance API.
    appearance: {
      theme: 'stripe',
      layout: {
        type: 'tabs',
        defaultCollapsed: false,
      },
    },
  };

  return (
    <Modal
      open
      onClose={closeModal}
      className="flex-row align-c jstfy-c"
    >
      <Box
        id="PaymentModal"
        style={{
          width: 1065,
          overFlowY: 'auto',
          padding: 32,
          background: '#fff',
          borderRadius: 8,
        }}
      >
        <h5 className="mb-2 text-600">
          Checkout
        </h5>
        <p className="mb-3">Add a card below and proceed to payment</p>
        <div className="d-flex gap-3">
          <div className="w-50">
            <Card>
              <CardHeader>
                <img src={CardImage} alt="Order" />
                <h6 className="fw-semibold pl-2">Payment Method</h6>
              </CardHeader>
              <CardBody>
                <Elements stripe={stripePromise} options={options}>
                  <CheckoutForm price={getPriceByMembership(membership) * membersCount} membership={membership} />
                </Elements>
              </CardBody>
            </Card>
          </div>
          <div className="w-50">
            <Card>
              <CardHeader>
                <img src={OrderImage} alt="Order" />
                <h6 className="fw-semibold pl-2">Order Summary</h6>
              </CardHeader>
              <CardBody>
                <h6 className="fw-semibold size-xs py-2 px-3 mb-2">Subscription Plan</h6>
                <div className="px-3 d-flex justify-content-between">
                  <span>{`${membership} Plan`}</span>
                  <span>{`$${getPriceByMembership(membership) / 100}`}</span>
                </div>
                <hr />
                <div className="px-3 d-flex justify-content-between">
                  <span className="fw-medium">Total</span>
                  <span className="fw-medium">
                    {`$${(getPriceByMembership(membership) * membersCount) / 100}`}
                  </span>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

PaymentModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  membership: PropTypes.string.isRequired,
  membersCount: PropTypes.number.isRequired,
};

export default PaymentModal;
