import React from 'react';
import { styled } from '@mui/material/styles';
import {
  TimerIcon,
  TeamIcon,
  NotificationIcon,
  TeamSettingsIcon,
  LocationIcon,
  TwoFactorIcon,
  ScheduleIcon,
  InsiderThreatsIcon,
  HelpIcon,
  ScreenshotIcon,
  SettingsIcon,
  SupportIcon,
  WebcamIcon,
  VacationIcon,
  EmailIcon,
} from '../../../components/Icon';

const paymentFeatures = [
  { icon: TimerIcon, title: 'Desktop Time Tracking App' },
  { icon: TeamIcon, title: 'Team Engagement Tools' },
  { icon: NotificationIcon, title: 'Scheduled Email Notifications' },
  { icon: TeamSettingsIcon, title: 'Team Members Management' },
  { icon: LocationIcon, title: 'Geo Dashboard' },
  { icon: TwoFactorIcon, title: 'Two-Factor Authentication' },
  { icon: ScheduleIcon, title: 'Detailed Timesheets' },
  { icon: InsiderThreatsIcon, title: 'Insider Threat Monitoring' },
  { icon: HelpIcon, title: 'Comprehensive Help Center' },
  { icon: ScreenshotIcon, title: 'Screenshot Frequency Control' },
  { icon: SettingsIcon, title: 'Advanced Settings & Permissions' },
  { icon: SupportIcon, title: 'Chat Support' },
  { icon: WebcamIcon, title: 'Webcam Audit' },
  { icon: VacationIcon, title: 'Time Off Requests Management' },
  { icon: EmailIcon, title: 'Email Support with 24x5 SLA' },
];

const StyledFeature = styled('div')`
  max-width: 276px;
  width: 100%;
  font-size: 14px;
  border-radius: 8px;
  box-shadow: 0px 4px 30px 0px rgba(40, 65, 183, 0.10);
`;

function Features() {
  return (
    <div className="payment-features d-flex flex-wrap gap-2">
      { paymentFeatures.map((feature, i) => (
        <StyledFeature key={i} className="d-flex align-items-center p-2 feature">
          <feature.icon />
          <span className="pl-2">{feature.title}</span>
        </StyledFeature>
      )) }
    </div>
  );
}

export default Features;
