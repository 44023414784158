/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Route, Switch } from 'react-router-dom';
import './app.css';
import { PrivateRoute } from './components';
import { NotificationProvider } from './contexts/Notification';
import { UserProvider } from './contexts/User';
import TwoFactorAuthentication from './screen/TwoFactorAuthentication/index';
import Activity from './screen/activity';
import AWSSetupInstructions from './screen/awsSetupInstructions';
import Desktop from './screen/desktop';
import GeoDashboard from './screen/geoDashboard';
import InsiderThreats from './screen/insiderThreats';
import MemberThreats from './screen/insiderThreats/MemberThreats';
import InvalidInvitation from './screen/invalidInvitation';
import InvitationExpired from './screen/invitationExpired';
import Login from './screen/login';
import Members from './screen/members';
import OrganizationSignup from './screen/organizationSignup';
import ResetPassword from './screen/resetPassword';
import EmailForResetPassword from './screen/resetPassword/EmailForResetPassword';
import ScreenshotFrequency from './screen/screenshotFrequency';
import Settings from './screen/settings';
import Signup from './screen/signup';
import TeamEngagement from './screen/teamEngagement';
import TeamView from './screen/teamEngagement/TeamView';
import Timesheets from './screen/timesheets';
import ViewActivity from './screen/viewActivity';
import WebcamAudit from './screen/webcamAudit';
import Workspaces from './screen/workspaces';
import TimeWorked from './screen/reports';
import WebcamAudits from './screen/reports/WebcamAudits';
import GeoLocations from './screen/reports/GeoLocations';
import WorkspacesForSuperAdmin from './screen/workspacesForSuperadmin';
import OpenShootApp from './screen/workspaces/OpenShootApp';
import { PtoRequest, MembersPto } from './screen/pto';
import Download from './screen/download';
import Report from './screen/teamEngagement/Report';
import Dashboard from './screen/dashboard';
import Organizations from './screen/admin/organizations';
import Teams from './screen/admin/teams';
import Users from './screen/admin/users';
import Superadminworkspaces from './screen/admin/Superadminworkspaces';
import CreateOrganization from './screen/CreateOrganization';
import Homepage from './screen/homepage';
import Account from './screen/account';
import Payments from './screen/settings/payments';

class App extends PureComponent {
  render() {
    const { hostname } = window.location;
    const publicHostnames = process.env.REACT_APP_PUBLIC_HOSTNAMES.split(',');
    const appHostnames = process.env.REACT_APP_APP_HOSTNAMES.split(',');
    const isDev = process.env.REACT_APP_NODE_ENV === 'development';
    const isHomePageMode = isDev && process.env.REACT_APP_ENABLE_HOMEPAGE_ON_LOCAL;

    const homeRoutes = (
      <Route exact path="/" component={Homepage} />
    );

    const appRoutes = [
      <Route exact path="/" component={Login} />,
      <Route exact path="/signup" component={Signup} />,
      <Route exact path="/desktop" component={Desktop} />,
      <Route exact path="/download" component={Download} />,
      <Route exact path="/organization-signup" component={OrganizationSignup} />,
      <Route exact path="/organization-signup/aws-setup" component={AWSSetupInstructions} />,
      <Route exact path="/reset-password" component={ResetPassword} />,
      <Route exact path="/reset-password/email" component={EmailForResetPassword} />,
      <Route exact path="/twoFactor" component={TwoFactorAuthentication} />,
      <Route exact path="/workspaces" component={Workspaces} />,
      <Route exact path="/invalid-invitation" component={InvalidInvitation} />,
      <Route exact path="/invitation-expired" component={InvitationExpired} />,
      <Route exact path="/account" component={Account} />,
      <Route exact path="/workspace" component={OpenShootApp} />,
      <Route exact path="/pto/requests" component={PtoRequest} />,
      <Route exact path="/workspaces-for-superadmin" component={WorkspacesForSuperAdmin} />,
      <Route exact path="/workspaces-for-superadmin" component={WorkspacesForSuperAdmin} />,
      <Route exact path="/admin/dashboard" component={Dashboard} />,
      <Route exact path="/admin/organizations" component={Organizations} />,
      <Route exact path="/admin/workspaces" component={Superadminworkspaces} />,
      <Route exact path="/admin/teams" component={Teams} />,
      <Route exact path="/admin/users" component={Users} />,
      <Route exact path="/admin/organization/create" component={CreateOrganization} />,

      // Private Routes
      <PrivateRoute exact path="/members/manage" component={Members} />,
      <PrivateRoute exact path="/members/timesheets" component={Timesheets} />,
      <PrivateRoute exact path="/members/activity" component={Activity} />,
      <PrivateRoute exact path="/members/screenshotFrequency" component={ScreenshotFrequency} />,
      <PrivateRoute exact path="/members/webcamAudit" component={WebcamAudit} />,
      <PrivateRoute exact path="/members/activity/view" component={ViewActivity} />,
      <PrivateRoute exact path="/members/team-engagement" component={TeamEngagement} accountLevel={2} />,
      <PrivateRoute exact path="/geoDashboard" component={GeoDashboard} />,
      <PrivateRoute exact path="/members/:memberId/location-violation-history" component={GeoDashboard} />,
      <PrivateRoute exact path="/members/team-engagement/:teamId" component={TeamView} accountLevel={2} />,
      <PrivateRoute exact path="/members/team-engagement/:teamId/member/:memberId" component={Report} accountLevel={2} />,
      <PrivateRoute exact path="/insiderThreats" component={InsiderThreats} />,
      <PrivateRoute exact path="/insiderThreats/:memberId" component={MemberThreats} />,
      <PrivateRoute exact path="/reports/time-worked" component={TimeWorked} />,
      <PrivateRoute exact path="/reports/webcam-results" component={WebcamAudits} />,
      <PrivateRoute exact path="/reports/geo-results" component={GeoLocations} />,
      <PrivateRoute exact path="/admin/settings" component={Settings} />,
      <PrivateRoute exact path="/pto/members" component={MembersPto} accountLevel={2} />,
      <PrivateRoute exact path="/admin/settings/payments" component={Payments} />,
    ];

    return (
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <UserProvider>
          <NotificationProvider>
            <Switch>
              {(isHomePageMode || (!isDev && publicHostnames.includes(hostname))) ? (
                homeRoutes
              ) : (isDev || appHostnames.includes(hostname)) && (
                appRoutes
              )}
            </Switch>
          </NotificationProvider>
        </UserProvider>
      </GoogleOAuthProvider>
    );
  }
}

export default App;
