import React, {
  useState, useEffect, useLayoutEffect, useRef,
} from 'react';
import { CardContent, CardHeader, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import Images from '../../common/images';
import Avatar from '../../components/avatar';
import CustomCard from './CustomCard';
import { calculateTimeSummary } from '../../utils/activity';

function TeamMemberCard({ team }) {
  const ref = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [mates, setMates] = useState([]);

  useLayoutEffect(() => {
    const rect = ref.current.getBoundingClientRect();
    const size = Math.floor(rect.width / 66);
    setPageSize(size * 2);
    if (team?.teamMates?.length) {
      const length = team?.teamMates?.length ?? 0;
      setTotalPage(Math.ceil(length / size / 2));
    }
  });

  useEffect(() => {
    if (team?.teamMates?.length) {
      const currentMates = team.teamMates.slice((currentPage - 1) * pageSize, currentPage * pageSize);
      setMates(currentMates);
      const length = team?.teamMates?.length ?? 0;
      setTotalPage(Math.ceil(length / pageSize));
    }
  }, [team, currentPage, pageSize]);

  const nextPage = () => {
    if (currentPage < totalPage) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <CustomCard>
      <CardHeader
        className="team-card-header"
        title={(
          <div className="team-card-header-title d-flex gap-1 align-items-center">
            TEAM MEMBERS -
            {' '}
            {team?.teamMates?.length}
            <Tooltip
              arrow
              placement="right"
              title={`Your team comprises ${team?.teamMates?.length} individuals,
                a crucial factor for effective collaboration and project management.`}
            >
              <img src={Images.infoBorderIcon} alt="info" />
            </Tooltip>
          </div>
        )}
        action={(
          <div className="d-flex gap-1">
            <button type="button" onClick={() => prevPage()} disabled={currentPage === 1}>
              <img
                className={`arrow-button ${currentPage === 1 ? 'arrow-disabled' : ''}`}
                src={Images.arrowLeftIcon}
                alt="previous page"
              />
            </button>
            <button type="button" onClick={() => nextPage()} disabled={currentPage === totalPage}>
              <img
                className={`arrow-button ${currentPage === totalPage ? 'arrow-disabled' : ''}`}
                src={Images.arrowRightIcon}
                alt="next page"
              />
            </button>
          </div>
        )}
      />
      <CardContent sx={{ height: '164px' }} className="pb-3">
        <div className="d-flex gap-2 flex-wrap" ref={ref}>
          {
            mates?.length > 0 && mates.map((mate) => {
              const memberName = `${mate.member?.user.firstName} ${mate.member?.user.lastName}`;
              const { totalSeconds, activeSeconds } = calculateTimeSummary(mate.member.activity);
              const totalActivityPercentage = Math.round((activeSeconds * 100) / totalSeconds || 0);
              // eslint-disable-next-line no-nested-ternary
              const borderColor = totalActivityPercentage >= 51
                ? '#53A551'
                : totalActivityPercentage >= 21
                  ? '#F19E41'
                  : '#CC4525';
              const backgroundColor = totalActivityPercentage >= 75 ? '#53A55116' : '#F5C0B016';
              const color = totalActivityPercentage >= 75 ? 'rgba(83, 165, 81, 1)' : 'rgba(204, 69, 37, 1)';
              return (
                <Tooltip
                  key={mate.memberId}
                  placement="bottom"
                  title={`${memberName} - ${totalActivityPercentage}%`}
                >
                  <div>
                    <Avatar
                      src={mate.member?.user.profilePicture}
                      name={(mate.member && mate.member.user.firstName ? mate.member.user.firstName[0] : 'N')
                        + (mate.member && mate.member.user.lastName ? mate.member.user.lastName[0] : 'A')}
                      width={48}
                      height={48}
                      color={color}
                      borderColor={borderColor}
                      backgroundColor={backgroundColor}
                      outline
                    />
                  </div>
                </Tooltip>
              );
            })
          }
        </div>
      </CardContent>
    </CustomCard>
  );
}

TeamMemberCard.propTypes = {
  team: PropTypes.shape({
    teamMates: PropTypes.arrayOf(
      PropTypes.shape({
        memberId: PropTypes.number.isRequired,
        member: PropTypes.shape({
          user: PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            profilePicture: PropTypes.string,
          }),
        }),
      }),
    ),
  }),
};

export default TeamMemberCard;
