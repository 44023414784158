import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import styled from '@emotion/styled';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormControl, InputLabel } from '@mui/material';
import { useJsApiLoader } from '@react-google-maps/api';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const Required = styled.span`
  color: 'red';
  font-size: '20px';
  margin-left: '4px';
`;

function PlacesAutocompleteComponent({
  label, location, onChange, inputRef, onKeyDown, required, textfieldType, placeholder,
}) {
  const [loading, setLoading] = useState(false);
  const [currentLocationOption, setCurrentLocationOption] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const {
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  const handleUseCurrentLocation = async () => {
    if (navigator.geolocation) {
      setLoading(true); // Set loading to true when fetching location

      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            // Fetch address using geocode
            const results = await getGeocode({ location: { lat: latitude, lng: longitude } });
            const address = results[0]?.formatted_address || 'Unknown Location';

            // Set location option with fetched data
            setCurrentLocationOption({
              description: address,
              structured_formatting: {
                main_text: address,
                secondary_text: '',
              },
              latlng: { lat: latitude, lng: longitude },
            });

            // Clear any existing error messages
            setErrorMessage(null);

            // Update the parent component with the new location
            onChange((prevState) => ({
              ...prevState, latlng: { lat: latitude, lng: longitude }, location: { description: address },
            }));
          } catch (error) {
            setErrorMessage('Error fetching address. Please try again.');
          }
          setLoading(false); // Stop loading once done
        },
        (error) => {
          // Handle geolocation errors
          // eslint-disable-next-line no-console
          console.error('Error fetching current location:', error);
          setErrorMessage('Unable to fetch current location. Please ensure location services are enabled.');
          setLoading(false); // Stop loading in case of error
        },
      );
    } else {
      setErrorMessage('Geolocation is not supported by your browser.');
      setLoading(false); // Stop loading if geolocation is not supported
    }
  };

  const enhancedOptions = [
    ...(currentLocationOption ? [currentLocationOption] : []),
    ...(status === 'OK' ? data : []),
  ];

  return (
    <FormControl sx={{ marginBottom: '16px' }} variant="standard" fullWidth>
      {label
            && (
            <InputLabel sx={{ fontSize: '18px', color: '#212529' }} shrink htmlFor="location-autocomplete">
              {label}
              {required && <Required>*</Required>}
            </InputLabel>
            )}
      <Autocomplete
        id="home-address"
        size="small"
        getOptionLabel={(option) => option?.description ?? ''}
        filterOptions={(x) => x}
        options={enhancedOptions}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={location}
        noOptionsText="No locations"
        data-testid="home-address"
        sx={
              (theme) => ({
                'label + &': {
                  marginTop: theme.spacing(3),
                },
                '& .MuiAutocomplete-endAdornment': {
                  display: 'none', // This hides the arrow icon
                },
                '& .Mui-focused': {
                  '& .MuiOutlinedInput-notchedOutline': {
                    boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                    borderWidth: '1px !important',
                    borderColor: theme.palette.primary.main,
                  },
                },
              })
            }
        onChange={(event, newValue) => {
          if (newValue) {
            if (newValue.latlng) {
              onChange((prevState) => ({ ...prevState, latlng: newValue.latlng, location: newValue }));
            } else {
              getGeocode({ address: newValue.description }).then((results) => {
                const { lat, lng } = getLatLng(results[0]);
                onChange((prevState) => ({ ...prevState, latlng: { lat, lng }, location: newValue }));
              });
            }
          } else {
            onChange((prevState) => ({ ...prevState, latlng: null, location: null }));
          }
        }}
        onInputChange={(event, newInputValue) => {
          setValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...params}
            fullWidth
            inputRef={inputRef}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            variant={textfieldType}
          />
        )}
        renderOption={(props, option) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.main_text}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )}
        isOptionEqualToValue={(option, value) => (
          option.description === value.description
        )}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end', // Aligns content to the right
          marginTop: '8px',
        }}
      >
        <Typography
          component="div"
          onClick={handleUseCurrentLocation}
          sx={{
            cursor: 'pointer',
            color: '#53A551',
            display: 'inline-flex',
            alignItems: 'center',
            fontSize: '16px', // Font size for better alignment
          }}
        >
          <MyLocationIcon
            sx={{
              fontSize: '20px',
              color: '#53A551',
              marginRight: '8px', // Adds space between icon and text
            }}
          />
          {loading ? 'Locating you...' : 'Locate Me'}

          {/* Show loader while fetching location */}
          {loading && (
          <CircularProgress sx={{ marginLeft: '8px' }} size={20} />
          )}
        </Typography>
      </Box>
      {errorMessage && (
        <Typography
          sx={{
            color: 'red',
            marginTop: '8px',
            fontSize: '14px',
          }}
        >
          {errorMessage}
        </Typography>
      )}
    </FormControl>
  );
}

PlacesAutocompleteComponent.propTypes = {
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  inputRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  onKeyDown: PropTypes.func,
  required: PropTypes.bool,
  textfieldType: PropTypes.string,
  placeholder: PropTypes.string,
};

function PlacesAutocomplete(props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  });

  if (isLoaded) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <PlacesAutocompleteComponent {...props} />;
  }
  return null;
}

PlacesAutocomplete.propTypes = {};

export default PlacesAutocomplete;
