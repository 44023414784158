import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import NavigationLayout from '../../../components/NavigationLayout';
import Overview from './Overview';
import Plans from './Plans';
import History from './History';
import Address from './Address';
import Members from './Members';

const StyledCard = styled(Card)`
  border-radius: 8px;
  background: var(--Dark-Dark-11-White, #FFF);
  box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 3px 5px 0px rgba(9, 30, 66, 0.20);
  padding-top: 8px;
  min-height: calc(100vh - 200px);
`;

const StyledTab = styled(Tab)`
  text-transform: capitalize;
  color: var(--Dark-Dark-03, #546077);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  &.Mui-selected {
    color: var(--Dark-Dark-01, #0E1E40);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  }
`;

function CustomTabPanel(props) {
  const {
    children, value, index, p = 4,
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
      <Box sx={{ p }}>
        {children}
      </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  p: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([null]),
  ]),
};

function Payments() {
  const location = useLocation();

  const {
    selectedTab,
  } = location.state || {}; // get user info from react router Link

  const [value, setValue] = useState(selectedTab || 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <NavigationLayout title="Settings & Permissions - Payment & Billing">
        <div style={{ marginTop: 24 }}>
          <StyledCard>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="settings tab"
                sx={{
                  '& .MuiTabs-indicator': {
                    backgroundColor: 'var(--Blue-Blue-01, #213D69)',
                  },
                }}
              >
                <StyledTab label="Overview" id="tab_overview" />
                <StyledTab label="Plans" id="tab_plans" />
                <StyledTab label="History" id="tab_history" />
                <StyledTab label="Address" id="tab_address" />
                <StyledTab label="Member Changes" id="tab_member_changes" />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Overview setTab={setValue} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Plans />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <History />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <Address />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <Members />
            </CustomTabPanel>
          </StyledCard>
        </div>
      </NavigationLayout>
    </div>
  );
}

Payments.propTypes = {

};

export default Payments;
