import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, Button } from '@mui/material';
import { TextInput } from '../../components/muiCustomized/TextInput';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { NotificationContext } from '../../contexts/Notification';
import { ChipSelectSearch } from '../../components/muiCustomized/ChipSelectSearchable';

function CreateTeamModal({
  onTeamCreated,
  closeModal,
  workspaceId,
}) {
  const { displayNotification } = useContext(NotificationContext);
  const [isCreating, setIsCreating] = useState(false);
  const [name, setName] = useState('');
  const [selectedManagers, setSelectedManagers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [members, setMembers] = useState([]);

  const fetchMembers = async () => {
    const { success, data } = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.members(workspaceId),
      params: {
        pageSize: 0,
      },
    });
    if (success) {
      const filteredMembers = data.members
        .filter(({ haveJoined }) => haveJoined)
        .map((item) => ({
          id: item.id,
          name: `${item.user.firstName} ${item.user.lastName}`,
        }));
      filteredMembers.sort((item1, item2) => {
        if (item1.name > item2.name) return 1;
        if (item1.name < item2.name) return -1;
        return 0;
      });
      setMembers(filteredMembers);
    } else {
      displayNotification({
        message: 'There was an issue fetching members in this workspace. Please try again',
        severity: 'error',
      });
    }
  };

  useEffect(() => {
    fetchMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateTeam = async () => {
    setIsCreating(true);
    const { data, success } = await sendRequest({
      method: 'post',
      url: API.WORKSPACE.createTeam(workspaceId),
      data: {
        name,
        managers: selectedManagers,
        members: selectedMembers,
      },
    });
    if (success) {
      setTimeout(() => {
        onTeamCreated();
        closeModal();
      }, 500);
    } else {
      setFormErrors(data);
    }
  };

  const handleSetManagerNames = (e) => {
    setSelectedManagers(e.target.value);
  };

  const handleSetMemberNames = (e) => {
    setSelectedMembers(e.target.value);
  };

  const handleSelectAllManagers = () => {
    setSelectedManagers(members.filter((member) => !selectedMembers.includes(member)));
  };

  const handleSelectAllMembers = () => {
    setSelectedMembers(members.filter((member) => !selectedManagers.includes(member)));
  };

  return (
    <Modal open onClose={closeModal} className="flex-row align-c jstfy-c create-team-modal">
      <Box style={{
        width: 489,
        padding: 32,
        background: '#fff',
        borderRadius: 8,
        maxHeight: 768,
        overflowY: 'auto',
      }}
      >
        <div className="modal-header">
          <h5 className="m-b16 text-600">Create Team</h5>
        </div>
        <div className="modal-body p-0">
          <div className="input-wrapper">
            <TextInput
              label="Team Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Required"
              error={!!formErrors?.name}
              helperText={formErrors?.name}
            />
          </div>
          <div className="input-wrapper">
            <ChipSelectSearch
              id="managers"
              label="Add Leaders"
              placeholder="Add Leaders"
              selectAllBtn
              selectedMembers={selectedManagers}
              handleChange={handleSetManagerNames}
              handleSelectAll={handleSelectAllManagers}
              members={members.filter((member) => !selectedMembers.includes(member))}
            />
          </div>
          <br />
          <div className="input-wrapper">
            <ChipSelectSearch
              id="members"
              label="Add Team Members"
              placeholder="Add Team Members"
              selectAllBtn
              selectedMembers={selectedMembers}
              handleChange={handleSetMemberNames}
              handleSelectAll={handleSelectAllMembers}
              members={members.filter((member) => !selectedManagers.includes(member))}
            />
          </div>
        </div>
        <br />
        <div className="modal-footer border-0 p-0 mt-2">
          <Button
            sx={{
              marginRight: 2,
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              padding: '12px 16px 12px 16px',
            }}
            variant="outlined"
            onClick={closeModal}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            sx={{
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              padding: '12px 16px 12px 16px',
            }}
            variant="contained"
            onClick={handleCreateTeam}
            disabled={isCreating || !(name && selectedManagers.length && selectedMembers.length)}
          >
            {isCreating ? 'Creating...' : 'Create Team'}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

CreateTeamModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onTeamCreated: PropTypes.func.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

export default CreateTeamModal;
