import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Bar from './Bar';

const BarWrapper = styled.div`
  width: 100%;
  background: #E9ECF0;
  border-radius: 10px;
  display: flex;
  height: 16px;
`;

const HourLabel = styled.div`
    color: var(--dark-4, #7D8698);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
`;

const calculateNumberEmptySlots = (from, to) => {
  const minutesUntilSlot = moment.duration(to.diff(from)).asMinutes();
  return (minutesUntilSlot / 10) - 1;
};

// Groups the user's activity into an array of 144 Elements = 24 hours of the day * 10 minute time slots
// The element is null if no activity during the period
const formatActivityByMinute = (snapshots, curDate, timezone) => {
  let currentDate = moment.tz(curDate, timezone).startOf('day');
  const endDate = moment
    .utc(curDate)
    .tz(timezone)
    .startOf('day')
    .add(1, 'day');

  const formatted = snapshots.reduce((formattedActivity, snapshot, index) => {
    const slotMoment = moment.utc(snapshot.timeSlot).tz(timezone);
    const numEmptySlots = calculateNumberEmptySlots(currentDate, slotMoment);

    // If we have a snapshot with a duplicate slot, skip it:
    if (numEmptySlots === -1 && snapshots?.[index - 1]?.timeSlot === snapshot?.timeSlot) return formattedActivity;
    const emptySlots = [];
    for (let i = 0; i < numEmptySlots; i += 1) emptySlots.push(null);
    currentDate = slotMoment;
    return [...formattedActivity, ...emptySlots, snapshot];

    // We have to add an initial 'null' slot if we do not have a snapshot at midnight.
    // This is because the function above calculates the number of empty slots FROM THE LAST SNAPSHOT,
    // but for the first snapshot (midnight), there is no snapshot before it.
  }, moment.utc(snapshots?.[0]?.timeSlot).tz(timezone).isSame(currentDate) ? [] : [null]);
  const numEmptySlots = calculateNumberEmptySlots(currentDate, endDate);
  const emptySlots = [];
  for (let i = 0; i < numEmptySlots; i += 1) emptySlots.push(null);
  return [...formatted, ...emptySlots];
};

const INFO_FORMAT = {
  activeSeconds: 0,
  workBreakSeconds: 0,
  secondsElapsed: 0,
  inactiveSeconds: 0,
  color: null,
  activityPercentage: null,
  focusMessage: null,
};

const activityBarState = (activityInfo) => {
  const { activeSeconds, workBreakSeconds } = activityInfo;

  if (workBreakSeconds) {
    return { color: 'var(--yellow-2, #F19E41)', focusMessage: 'Work Break' };
  }
  if (activeSeconds >= 60 * 30) { // at least 30 minutes per hour of active time
    return { color: 'var(--blue-1, #213D69)', focusMessage: 'Deep Focus' };
  }
  if (activeSeconds >= 60 * 12) { // at least 15 minutes per hour of active time
    return { color: 'var(--blue-4, #5A81BC)', focusMessage: 'Focused' };
  }
  if (activeSeconds > 0) { // user was active at all
    return { color: 'var(--dark-5, #B5BAC4)', focusMessage: 'Active' };
  }
  return { color: 'transparent', focusMessage: null };
};

const calculateActivityBarInfo = (snapshots) => {
  const activityInfo = snapshots.reduce((accumulator, snapshot) => {
    // If there is no snapshot for this time, add 10 minutes of inactiveSeconds
    if (!snapshot) {
      return { ...accumulator, inactiveSeconds: accumulator.inactiveSeconds + 600 };
    }
    return {
      ...accumulator,
      activeSeconds: accumulator.activeSeconds + snapshot.activeSeconds,
      workBreakSeconds: accumulator.workBreakSeconds + snapshot.workBreakSeconds,
      secondsElapsed: accumulator.secondsElapsed + snapshot.secondsElapsed,
    };
  }, INFO_FORMAT);

  const { color, focusMessage } = activityBarState(activityInfo);

  activityInfo.color = color;
  activityInfo.focusMessage = focusMessage;
  activityInfo.activityPercentage = activityInfo.activeSeconds
    ? ((activityInfo.activeSeconds / activityInfo.secondsElapsed) * 100).toFixed(0) : null;

  return activityInfo;
};

// Returns an array of 24 arrays. 1 array for each hour of the day
const formatHourActivity = (curDate, timezone, snapshots = []) => {
  const minuteActivity = formatActivityByMinute(snapshots, curDate, timezone);

  const hourActivity = Object.values(minuteActivity.reduce((accumulator, timeSlot, index) => {
    const hours = accumulator;
    const hourOfTheDay = Math.floor(index / 6);
    if (index % 6 === 0) hours[hourOfTheDay] = [];
    hours[hourOfTheDay].push(timeSlot);
    return hours;
  }, {}));

  return hourActivity.reduce(
    (infoAccumulator, hourInfo) => [...infoAccumulator, calculateActivityBarInfo(hourInfo)],
    [],
  );
};

function ActivityBar({ snapshots, curDate, timezone }) {
  // snapshots.sort((a, b) => new Date(a.timeSlot) - new Date(b.timeSlot));
  const hourlyInfo = useMemo(() => formatHourActivity(curDate, timezone, snapshots), [snapshots]);
  return (
    <div className="d-flex align-items-center gap-2">
      <HourLabel className="size-xxs">Start</HourLabel>
      <BarWrapper>
        {hourlyInfo.map((hourInfo, i) => (
          <Bar
            key={i}
            hourInfo={hourInfo}
            hour={i}
            isAfterSameColor={hourInfo.color === hourlyInfo?.[i - 1]?.color}
            isBeforeSameColor={hourInfo.color === hourlyInfo?.[i + 1]?.color}
          />
        ))}
      </BarWrapper>
      <HourLabel className="size-xxs">Stop</HourLabel>
    </div>
  );
}

ActivityBar.propTypes = {
  snapshots: PropTypes.arrayOf(PropTypes.shape({

  })),
  curDate: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
};

export default ActivityBar;
