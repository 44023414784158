import React, {
  useState, useEffect, useContext,
} from 'react';
import { Box, Button } from '@mui/material';
import { UserContext } from '../../../contexts/User';
import { sendRequest } from '../../../api/shootAPI';
import API from '../../../api/endpoints';
import '../settings.css';
import CustomTable from '../../../components/table/CustomTable';

const TABLE_HEADERS = [
  {
    label: 'Amount',
    sortable: false,
  },
  {
    label: 'Description',
    sortable: false,
  },
  {
    label: 'Payment Method',
    sortable: false,
  },
  {
    label: 'Action',
    sortable: false,
  },
];

const CustomTableStyles = {
  container: {
    marginTop: 1,
  },
  header: {
    backgroundColor: 'var(--dark-10)',
    fontSize: '16px',
    '.MuiTableSortLabel-iconDirectionAsc': {
      transform: 'rotate(0deg) !important',
    },
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff !important',
    },
    '&:hover': {
      backgroundColor: 'var(--dark-10) !important',
    },
  },
  cell: {
    button: {
      textWrap: 'nowrap',
      color: 'var(--dark-2)',
      borderColor: 'var(--dark-4) !important',
    },
  },
};

function History() {
  const { activeWorkspace } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const getInvoices = async () => {
    setIsLoading(true);
    const res = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.getInvoices(activeWorkspace.id),
    });

    const updatedCharges = res.data.invoices.data.map((charge) => ({
      amount: `$${(charge.amount / 100).toFixed(2)}`,
      description: `${charge.calculated_statement_descriptor} - Subscription`,
      method: `${charge.payment_method_details.card.brand} ending in ${charge.payment_method_details.card.last4}`,
      actions: (
        <Button
          href={charge.receipt_url}
          target="_blank"
          rel="noreferrer"
          variant="outlined"
          sx={{
            color: 'var(--dark-2)',
            borderColor: 'var(--dark-4)',
          }}
        >
          View Receipt
        </Button>
      ),
    }));

    setInvoices(updatedCharges);
    setIsLoading(false);
  };

  useEffect(() => {
    getInvoices();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Box className="d-flex justify-content-between align-items-center">
        <Box>
          <Box className="size-s text-600 lh-base fc-dark mb-1">
            Billing History
          </Box>
          <Box className="size-xs text-400 lh-sm fc-dark">
            A department is a functional unit within your organization
          </Box>
        </Box>
      </Box>
      <CustomTable
        headers={TABLE_HEADERS}
        items={invoices}
        styles={CustomTableStyles}
        loading={isLoading}
      />
    </>
  );
}

export default History;
