import moment from 'moment-timezone';
import React, {
  useEffect, useMemo, useState, useContext,
} from 'react';
import { useLocation } from 'react-router';
import DatePicker from '../../components/datePicker';
import { getDatesInRange, getWeekRange, convertSecToTimeFormat } from '../../utils/time';
import NavigationLayout from '../../components/NavigationLayout';
import SnapshotRow from './SnapshotRow';
import AuditRow from './AuditRow';
import API from '../../api/endpoints';
import { sendRequest } from '../../api/shootAPI';
import './viewActivity.css';
// import DailyHighlights from './DailyHighlights';
import ViewActivityTable from './ViewActivityTable';
import { UserContext } from '../../contexts/User';
import { NotificationContext } from '../../contexts/Notification';
import TimezoneSelect from '../../components/selectors/TimezoneSelect';

// Takes the minutes value of the start time as the [key]--the value is the
// array position for displaying the activity in the UI
const MINUTES_DISPLAY_POSITION = {
  0: 0,
  10: 1,
  20: 2,
  30: 3,
  40: 4,
  50: 5,
};

// Breaks the snapshots into 10 minute intervals
const formatSnapshotsByHour = (timezone, snapshots) => snapshots.reduce((reducer, snapshot) => {
  // iterate through each snapshot which are 15 minutes each, and bucket them by hour (UTC time)
  // Output:
  // {
  //   9: [/* all snapshots that begin with 09:00 will go in here */] }
  //   15: [/* all snapshots that begin with 15:00 will go in here */] }
  // }
  const hour = moment.utc(snapshot.timeSlot).tz(timezone).hours();
  const minutes = moment.utc(snapshot.timeSlot).tz(timezone).minutes();
  const localTime = moment.utc(snapshot.timeSlot).tz(timezone);

  const formattedSnapshot = {
    data: snapshot,
    localStartTime: localTime.format('h:mm A'),
    localBlockTime: localTime.format('h:mm A'),
    roundedStartTime: localTime.format('h:mm A'),
    roundedEndTime: localTime.add(10, 'minutes').format('h:mm A'),
  };

  // Use the MINUTES_DISPLAY_POSITION to insert the snapshot into the correct index
  const snapshotList = reducer[hour] ?? [null, null, null, null, null, null];
  snapshotList[MINUTES_DISPLAY_POSITION[minutes]] = formattedSnapshot;
  return { ...reducer, [hour]: snapshotList };
}, {});

const formatWebcamAuditByDay = (timezone, webcamAudits) => webcamAudits.reduce((reducer, webcamAudit) => {
  // iterate through each webcam audit and bucket them by day (UTC time)
  // Output:
  // {
  //   2023/07/03: [/* all webcam audits withiin this day will go in here */] }
  // }
  const localTime = moment.utc(webcamAudit.timestamp).tz(timezone).format('h:mm A');
  const localDate = moment.utc(webcamAudit.timestamp).tz(timezone).format('YYYY/MM/DD');

  const formattedWebcamAudit = {
    data: webcamAudit,
    localTime,
  };

  const webcamAuditList = reducer[localDate]
    ?? [];
  webcamAuditList.push(formattedWebcamAudit);
  return { ...reducer, [localDate]: webcamAuditList };
}, {});

const getTableTimeAndAcitivity = (activities, dates, timezone) => {
  const times = [];
  const avgActivities = [];
  let totalSecondsElapsed = 0;
  let totalActiveSeconds = 0;
  dates.forEach((day) => {
    // get total active time for each date
    const startDay = moment.tz(day, 'MM/DD/YYYY', timezone).startOf('day').utc();
    const endDay = moment.tz(day, 'MM/DD/YYYY', timezone).endOf('day').utc();
    const initialdateActivity = { secondsElapsed: 0, activeSeconds: 0 };
    const dateActivity = activities.reduce(
      (acc, activity) => {
        // Check if the time is within the local day range
        const compareDay = moment.utc(activity.startDate);

        if (compareDay.isSameOrAfter(startDay) && compareDay.isSameOrBefore(endDay)) {
          return {
            secondsElapsed: acc.secondsElapsed + activity.secondsElapsed,
            activeSeconds: acc.activeSeconds + activity.activeSeconds,
          };
        }
        return { ...acc };
      },
      initialdateActivity,
    );
    totalSecondsElapsed += dateActivity.secondsElapsed;
    totalActiveSeconds += dateActivity.activeSeconds;
    times.push(moment.utc(dateActivity.secondsElapsed * 1000).format('H:mm:ss'));
    const avgActivity = dateActivity.secondsElapsed
      ? Number((((dateActivity.activeSeconds / dateActivity.secondsElapsed) || 0) * 100).toFixed(0))
      : null;
    avgActivities.push(avgActivity);
  });
  const totalTime = convertSecToTimeFormat(totalSecondsElapsed);
  const totalAvgActivity = totalSecondsElapsed
    ? Number((((totalActiveSeconds / totalSecondsElapsed) || 0) * 100).toFixed(0))
    : null;
  return {
    times, avgActivities, totalTime, totalAvgActivity,
  };
};

function ViewActivity() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const memberId = queryParams.get('member');
  const { activeWorkspace, user } = useContext(UserContext);
  const { displayNotification } = useContext(NotificationContext);
  const {
    workspaceMember: member,
    previouslySelectedDate,
  } = location.state || {}; // get user info from react router Link
  const [activityType, setActivityType] = useState(location.state?.activityType ?? 'Screenshots');
  const [date, setDate] = useState(previouslySelectedDate ?? new Date());
  const [snapshots, setSnapshots] = useState({ data: [], formatted: {} });
  const [webcamAudits, setWebcamAudits] = useState({ data: [], formatted: {} });
  const [tableData, setTableData] = useState({
    dates: [], times: [], avgActivities: [], totalTime: '0:00:00', totalAvgActivity: null,
  });
  const [showCaptures, setShowCaptures] = useState(false);
  const [collapseTable, setCollapseTable] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState(user.timezone);
  const [workspaceMember, setWorkspaceMember] = useState(member);

  const getTableData = async (selectedDate, timezone = selectedTimezone) => {
    const weekRange = getWeekRange(selectedDate);
    const dates = getDatesInRange(new Date(weekRange.start), new Date(weekRange.end));
    const startOfDate = moment(dates[0]).startOf('day');
    const endOfDate = moment(dates[6]).endOf('day');
    const params = {
      start: startOfDate.format('YYYY-MM-DD HH:mm:ss'),
      end: endOfDate.format('YYYY-MM-DD HH:mm:ss'),
      timezone,
    };
    const res = await sendRequest({ method: 'get', url: API.MEMBER.activityList(workspaceMember.id), params });
    if (res.success) {
      const data = res?.data?.list || [];
      const {
        times, avgActivities, totalTime, totalAvgActivity,
      } = getTableTimeAndAcitivity(data, dates, timezone);
      setTableData({
        dates, times, avgActivities, totalTime, totalAvgActivity,
      });
    }
  };

  const handleSelectDate = async (selectedDate, selectedActivityType, timezone = selectedTimezone) => {
    setDate(selectedDate);
    setActivityType(selectedActivityType);
    // const startOfDay = moment(selectedDate).tz(timezone).startOf('day').utc();
    const formattedSelectedDay = moment(new Date(selectedDate)).format('"MM/DD/YYYY"');

    const startOfDay = moment.tz(formattedSelectedDay, 'MM/DD/YYYY', timezone).startOf('day').utc();
    const endOfDay = moment.tz(formattedSelectedDay, 'MM/DD/YYYY', timezone).endOf('day').utc();
    const params = {
      start: startOfDay.format('YYYY-MM-DD HH:mm:ss'),
      end: endOfDay.format('YYYY-MM-DD HH:mm:ss'),
      timezone,
    };

    getTableData(selectedDate, timezone);

    if (selectedActivityType === 'Screenshots') {
      const res = await sendRequest({ method: 'get', url: API.MEMBER.activity(workspaceMember.id), params });
      if (res.success) {
        const data = res?.data?.list || [];
        const formattedSnapshots = res.data?.list?.length > 0
          ? formatSnapshotsByHour(timezone, res.data.list) : {};
        setSnapshots({ data, formatted: formattedSnapshots });
      }
    } else {
      const res = await sendRequest({ method: 'get', url: API.MEMBER.webcamAudit(workspaceMember.id), params });
      if (res.success) {
        const data = res?.data?.list || [];
        const formattedWebcamAudit = res.data?.list?.length > 0
          ? formatWebcamAuditByDay(timezone, res.data.list) : {};
        setWebcamAudits({ data, formatted: formattedWebcamAudit });
      }
    }
  };

  const handleSelectTimezone = async (timezone, date, activityType) => {
    setSelectedTimezone(timezone);
    await handleSelectDate(date, activityType, timezone);
  };

  const fetchMemberInfo = async () => {
    const res = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.getMember(activeWorkspace.id, memberId),
    });
    if (res.success) {
      setWorkspaceMember(res.data.member);
    } else if (res.status === 400) {
      displayNotification({
        message: res.data.error,
        severity: 'error',
      });
    }
  };

  useEffect(() => {
    if (!workspaceMember) {
      // Fetch member info only when location.state.workspaceMember is null
      fetchMemberInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (workspaceMember) {
      // Fetches snapshots or webcamAudits on initial landing of page
      handleSelectDate(date, activityType);
    }
  }, [workspaceMember]);

  // Need to use localeCompare because Object.keys returns numbers as strings
  const snapshotsSortedByTime = useMemo(() => Object.keys(snapshots.formatted).sort(
    (a, b) => a.localeCompare(b, undefined, { numeric: true }),
  ), [snapshots]);

  const webcamAuditsSortedByTime = useMemo(
    () => Object.keys(webcamAudits.formatted).sort().reverse(),
    [webcamAudits],
  );

  const timezoneOptions = [{
    heading: 'Your timezone',
    description: user.timezone,
    value: user.timezone,
  }];

  if (workspaceMember) {
    timezoneOptions.push({
      heading: `${workspaceMember?.user.firstName}'s Timezone`,
      description: workspaceMember?.user.timezone,
      value: workspaceMember?.user.timezone,
    });
  }

  return (
    <NavigationLayout title="Timesheets" subtitle="View time worked and activity of workspace members">
      {/* <DailyHighlights snapshots={snapshots.data} /> */}
      <div className="subheading-container" style={{ marginTop: 28 }}>
        <DatePicker
          style={{ width: 246 }}
          value={date}
          onChange={(_date) => handleSelectDate(_date, activityType)}
          data-testid="datepicker"
        />
        <TimezoneSelect
          value={selectedTimezone}
          label="Timezone"
          onChange={(e) => handleSelectTimezone(e.target.value, date, activityType)}
          options={timezoneOptions}
          data-testid="timezone"
        />
      </div>
      {(tableData.times.length > 0 && tableData.avgActivities.length > 0) && (
        <ViewActivityTable
          tableData={tableData}
          date={date}
          handleSelectDate={handleSelectDate}
          collapseTable={collapseTable}
          setCollapseTable={setCollapseTable}
          showCaptures={showCaptures}
          setShowCaptures={setShowCaptures}
          activityType={activityType}
          workspaceMember={workspaceMember}
        />
      )}
      {showCaptures && (
        <section className="view-activity" data-testid="view-activity">
          {activityType === 'Screenshots' ? (
            snapshotsSortedByTime.map((snapshotHour) => (
              <SnapshotRow
                key={snapshotHour}
                hourBlock={snapshotHour}
                snapshots={snapshots.formatted[snapshotHour]}
                blurScreenshots={workspaceMember.blurScreenshots}
              />
            ))) : (
            webcamAuditsSortedByTime.map((webcamAuditDay) => (
              <AuditRow
                key={webcamAuditDay}
                dayBlock={webcamAuditDay}
                webcamAudits={webcamAudits.formatted[webcamAuditDay]}
              />
            ))
          )}
        </section>
      )}
    </NavigationLayout>
  );
}

export default ViewActivity;
