/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
} from '@mui/material';
import { sendRequest } from '../../../api/shootAPI';
import { UserContext } from '../../../contexts/User';
import API from '../../../api/endpoints';

function Members() {
  const { activeWorkspace } = useContext(UserContext);
  const [memberChanges, setMemberChanges] = useState([]);

  const getMemberChanges = async () => {
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.memberChanges(activeWorkspace.id),
    });

    if (response.success) {
      if (response.data.length) {
        setMemberChanges(response.data);
      }
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }

  useEffect(() => {
    getMemberChanges();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Box className="d-flex justify-content-between align-items-center">
        <Box>
          <Box className="size-s text-600 lh-base fc-dark  mb-1">
            Member Changes
          </Box>
          <Box className="size-xs text-400 lh-sm fc-dark">
            This page shows any changes in your workspace that
            affect your billing—such as adding or removing team members,
            or when a member becomes inactive. Non-billable updates, like
            adding bots, won’t be displayed here.
          </Box>
        </Box>
      </Box>
      <Box className="mt-4">
        {memberChanges.map((data, i) => (
          <Box className="d-flex justify-content-between align-items-center mb-4" key={i}>
            <p className="size-xs">
              <span className="color-success text-500">
                {data.user.firstName ? `${data.user.firstName} ${data.user.lastName}` : data.user.email}
              </span>
              {' '}
              <span className="fc-dark">
                {data.status}
              </span>
              {' '}
              by
              {' '}
              <span className="color-success text-500">
                {data.updatedBy}
              </span>
            </p>
            <p className="fc-dark text-600 size-xs">
              {formatDate(data.createdAt)}
            </p>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default Members;
