import React, { useContext, useEffect, useState } from 'react';
import { alpha } from '@mui/material/styles';
import {
  Button, Box, Grid, FormControl, TextField, Autocomplete, FormLabel,
} from '@mui/material';
import { TextInput } from '../../../components/muiCustomized/TextInput';
import { sendRequest } from '../../../api/shootAPI';
import { UserContext } from '../../../contexts/User';
import API from '../../../api/endpoints';

function Address() {
  const { activeWorkspace } = useContext(UserContext);
  const [formData, setFormData] = useState({
    id: null,
    country: '',
    companyName: '',
    streetAddress: '',
    suiteUnit: '',
    city: '',
    state: '',
    zip: '',
    additionalNote: '',
  });
  const [countryList, setCountryList] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const handleForm = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const fetchBillingAddress = async () => {
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.billingAddress(activeWorkspace.id),
    });

    if (response.success) {
      if (response.data) {
        setFormData(response.data);
        setIsUpdate(true);
      }
    }
  };

  const handleSubmit = async () => {
    let method = '';
    if (formData.id) {
      method = 'put';
    } else {
      method = 'post';
    }
    const response = await sendRequest({
      method,
      url: API.WORKSPACE.billingAddress(activeWorkspace.id),
      data: formData,
    });
    if (response.success) {
      setFormData(response.data);
    }
  };

  useEffect(() => {
    sendRequest({
      method: 'get',
      url: 'https://restcountries.com/v3.1/all',
    }).then(({ data }) => {
      setCountryList(data.map((country) => country.name.common));
    });

    fetchBillingAddress();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="px-3">
      <Box className="flex-row justify-content-between align-c py-2">
        <Box>
          <h5 className="size-s text-600 lh-base fc-dark mb-1">
            Billing Address & Details
          </h5>
          <p className="size-xs text-400 lh-sm fc-dark mb-3">
            This information will appear on all invoices associated with your
            account
          </p>
        </Box>
        {isEditing ? (
          <Box>
            <Button
              color="secondary"
              sx={{
                marginRight: 2,
                textTransform: 'capitalize',
                border: 'inset',
                borderWidth: 1,
                borderColor: '#7D8698',
              }}
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </Button>
            <Button variant="contained" sx={{ textTransform: 'capitalize' }} onClick={() => handleSubmit()}>
              Save Changes
            </Button>
          </Box>
        ) : (
          <Button
            variant="contained"
            sx={{ textTransform: 'capitalize' }}
            onClick={() => { setIsEditing(true); }}
          >
            {isUpdate ? 'Edit Details' : 'Add Details'}
          </Button>
        )}
      </Box>
      {
        isEditing ? (
          <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <FormControl sx={{ marginBottom: '16px' }} variant="standard" fullWidth>
                <FormLabel
                  sx={{
                    color: '#293855',
                    fontSize: '14px',
                    fontWeight: '500',
                  }}
                >
                  Country
                </FormLabel>
                <Autocomplete
                  disablePortal
                  id="country"
                  name="country"
                  value={formData.country}
                  onInputChange={(event, newInputValue) => {
                    handleForm('country', newInputValue);
                  }}
                  options={countryList}
                  renderInput={(params) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      name="country"
                      value={formData.country}
                      placeholder="Country"
                    />
                  )}
                  sx={
                    (theme) => ({
                      background: '#f9f9f9',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#ced4da!important',
                      },
                      '& .MuiInputBase-input': {
                        padding: '0 4px 0 3px !important',
                      },
                      '& .Mui-focused': {
                        '& .MuiOutlinedInput-notchedOutline': {
                          boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                          borderWidth: '1px !important',
                          borderColor: `${theme.palette.primary.main} !important`,
                        },
                      },
                    })
                  }
                />
              </FormControl>
              <TextInput
                label="Company Name"
                value={formData.companyName}
                onChange={(event) => handleForm(event.target.name, event.target.value)}
                placeholder="Required"
                background="#f9f9f9"
                name="companyName"
              />
              <TextInput
                label="Street Address"
                value={formData.streetAddress}
                onChange={(event) => handleForm(event.target.name, event.target.value)}
                placeholder="Required"
                background="#f9f9f9"
                name="streetAddress"
              />
              <TextInput
                label="Suite/Unit"
                value={formData.suiteUnit}
                onChange={(event) => handleForm(event.target.name, event.target.value)}
                placeholder="Required"
                background="#f9f9f9"
                name="suiteUnit"
              />
              <TextInput
                label="City"
                value={formData.city}
                onChange={(event) => handleForm(event.target.name, event.target.value)}
                placeholder="Required"
                background="#f9f9f9"
                name="city"
              />
              <TextInput
                label="State"
                value={formData.state}
                onChange={(event) => handleForm(event.target.name, event.target.value)}
                placeholder="Required"
                background="#f9f9f9"
                name="state"
              />
              <TextInput
                label="Zip"
                value={formData.zip}
                onChange={(event) => handleForm(event.target.name, event.target.value)}
                placeholder="Required"
                background="#f9f9f9"
                name="zip"
              />
            </Grid>
            <Grid item xs={6}>
              <p className="size-ms text-500 lh-base fc-dark mb-2">
                Additional Notes
              </p>
              <TextInput
                value={formData.additionalNote}
                onChange={(event) => handleForm(event.target.name, event.target.value)}
                placeholder="Required"
                background="#f9f9f9"
                multiline
                rows={7}
                name="additionalNote"
                helperText="Use this field for any additional information you’d like to include
                  on your billing statement."
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container rowSpacing={{ xs: 2, sm: 2, md: 3 }}>
            { isEditing ? (
              <>
                <Grid item xs={12}>
                  <h6 className="size-xs text-500 lh-sm fc-medium mb-2">Country</h6>
                  <p className="size-s text-500 lh-sm fc-dark">{formData.country}</p>
                </Grid>
                <Grid item xs={12}>
                  <h6 className="size-xs text-500 lh-sm fc-medium mb-2">Company Name</h6>
                  <p className="size-s text-500 lh-sm fc-dark">{formData.companyName}</p>
                </Grid>
                <Grid item xs={12}>
                  <h6 className="size-xs text-500 lh-sm fc-medium mb-2">Address</h6>
                  <p className="size-s text-500 lh-sm fc-dark">{formData.streetAddress}</p>
                  <p className="size-s text-500 lh-sm fc-dark">{formData.suiteUnit}</p>
                </Grid>
                <Grid item xs={12}>
                  <h6 className="size-xs text-500 lh-sm fc-medium mb-2">Additional Notes</h6>
                  <p className="size-s text-500 lh-sm fc-dark">{formData.additionalNote}</p>
                </Grid>
              </>
            ) : null}
          </Grid>
        )
      }
    </Box>
  );
}

export default Address;
