import React, {
  useEffect, useState, useMemo, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import './settings.css';
import CustomTable from '../../components/table/CustomTable';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';
import { AccrualSchedule } from '../../constants/offTimePolicy';
import DropDown from '../../components/dropdown';
import { NotificationContext } from '../../contexts/Notification';
import TimeOffPolicyModal from './TimeOffPolicyModal';
import TimeOffTabPageHeader from './TabPageHeader';
import DeleteModal from './DeleteModal';

function TimeoffActivePanel({
  getPolicies, isLoading, policies,
}) {
  const { activeWorkspace } = useContext(UserContext);
  const {
    displayNotification,
  } = useContext(NotificationContext);
  const [showNewModal, setShowNewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [teams, setTeams] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectedPolicy, setSelectedPolicy] = useState();

  const archiveOffTimePolicy = async (id) => {
    const res = await sendRequest({
      method: 'put',
      url: API.WORKSPACE.archiveOffTimePolicy(activeWorkspace.id, id),
    });
    if (res.success) {
      displayNotification({
        message: 'Policy has been archived successfully.',
        severity: 'success',
      });
      getPolicies();
    } else {
      displayNotification({
        message: 'An error occurs while archiving a PTO policy.',
        severity: 'error',
      });
    }
  };

  const deleteOffTimePolicy = async (id) => {
    const res = await sendRequest({
      method: 'delete',
      url: API.WORKSPACE.deleteOffTimePolicy(activeWorkspace.id, id),
    });
    if (res.success) {
      setShowDeleteModal(false);
      displayNotification({
        message: 'Policy has been deleted successfully.',
        severity: 'success',
      });
      getPolicies();
    } else {
      displayNotification({
        message: 'An error occurs while removing a PTO policy.',
        severity: 'error',
      });
    }
  };

  // eslint-disable-next-line react/prop-types
  const items = useMemo(() => policies.map((policy) => {
    if (!policy.isArchived) {
      return {
        name: policy.name,
        members: policy.members.length,
        teams: policy.teams.length,
        accrual_schedule: AccrualSchedule[policy.scheduleOfAccrual].text,
        action: (
          <DropDown
            title="Actions"
            options={[
              {
                text: 'Edit Policy',
                fontColor: '#061232',
                handleClick: () => {
                  setSelectedPolicy(policy);
                  setShowEditModal(true);
                },
              },
              {
                text: 'Archive Policy',
                fontColor: '#061232',
                handleClick: () => { archiveOffTimePolicy(policy.id); },
              },
              {
                text: 'Delete Policy',
                fontColor: '#CC4525',
                handleClick: () => {
                  setSelectedPolicy(policy);
                  setShowDeleteModal(true);
                },
              },
            ]}
          />
        ),
      };
    }
    return {};
  }), [policies]);

  const getTeams = async () => {
    const res = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.getTeams(activeWorkspace.id),
    });
    if (res.success) {
      setTeams(res.data.teams);
    } else {
      displayNotification({
        message: 'An error occurs while fetching teams.',
        severity: 'error',
      });
    }
  };

  const getMembers = async () => {
    const res = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.members(activeWorkspace.id),
      params: {
        pageSize: 0,
      },
    });
    if (res.success) {
      const membersArr = res.data.members.map((member) => {
        const name = member?.user?.firstName && member?.user?.lastName
          ? `${member.user.firstName} ${member.user.lastName}`
          : member.user.email;
        return {
          id: member.id,
          name,
        };
      });
      setMembers(membersArr);
    } else {
      displayNotification({
        message: 'An error occurs while fetching teams.',
        severity: 'error',
      });
    }
  };

  useEffect(() => {
    getPolicies();
    getTeams();
    getMembers();
  }, []);

  const FIXED_TABLE_HEADERS = [
    {
      label: 'Policy Name',
      sortable: true,
    },
    {
      label: 'Members',
      sortable: true,
    },
    {
      label: 'Teams',
      sortable: true,
    },
    {
      label: 'Accrual Schedule',
      sortable: true,
    },
    {
      label: 'Action',
    },
  ];
  return (
    <Box
      sx={{
        marginTop: '-3px',
      }}
    >
      {showNewModal
        && (
          <TimeOffPolicyModal
            closeModal={() => setShowNewModal(false)}
            getPolicies={getPolicies}
            teams={teams}
            members={members}
          />
        )}
      {showEditModal
        && (
          <TimeOffPolicyModal
            policy={selectedPolicy}
            closeModal={() => setShowEditModal(false)}
            getPolicies={getPolicies}
            teams={teams}
            members={members}
          />
        )}
      {showDeleteModal
        && (
          <DeleteModal
            policyName={selectedPolicy.name}
            setShowModal={setShowDeleteModal}
            callback={() => deleteOffTimePolicy(selectedPolicy.id)}
            type="policy"
          />
        )}
      <TimeOffTabPageHeader
        title="Time off policies"
        subtitle="Set up automatic accrual policies for time off"
        buttonName="Add Policy"
        onClickBtn={() => setShowNewModal(true)}
      />
      <Box className="mt-4">
        <CustomTable
          headers={FIXED_TABLE_HEADERS}
          items={items}
          loading={isLoading}
        />
      </Box>
    </Box>
  );
}

TimeoffActivePanel.propTypes = {
  policies: PropTypes.shape({ }),
  getPolicies: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default TimeoffActivePanel;
