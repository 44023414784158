import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { sendRequest } from '../../../api/shootAPI';
import API from '../../../api/endpoints';
import { UserContext } from '../../../contexts/User';

function CheckoutForm({ price, membership }) {
  const stripe = useStripe();
  const elements = useElements();
  const { activeWorkspace, setActiveWorkspace } = useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);

  const handleError = (error) => {
    setLoading(false);
    setErrorMessage(error.message);
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    const data = {
      price,
    };

    // Create the PaymentIntent and obtain clientSecret
    const res = await sendRequest({
      method: 'post',
      url: API.WORKSPACE.subscription(activeWorkspace.id),
      data,
    });

    const { client_secret: clientSecret } = await res.data;

    if (clientSecret) {
      const data = {
        membership,
      };

      const response = await sendRequest({
        method: 'put',
        url: API.WORKSPACE.updateMembership(activeWorkspace.id),
        data,
      });

      if (response.success) {
        setActiveWorkspace({
          ...activeWorkspace,
          membership,
        });
      }
    }

    // Confirm the PaymentIntent using the details collected by the Payment Element
    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: window.location.href,
      },
    });

    if (error) {
      // This point is only reached if there's an immediate error when
      // confirming the payment. Show the error to your customer (for example, payment details incomplete)
      handleError(error);
    } else {
      // Your customer is redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer is redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement className="pb-3" />
      <Button
        sx={{
          font: 'Inter',
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: '500',
          textTransform: 'none',
          borderRadius: '4px',
          padding: '7px 29px',
          color: '#FFFFFF',
          border: '1px solid',
          backgroundColor: '#53A551',
          '&:hover': {
            backgroundColor: '#53A551',
          },
        }}
        color="primary"
        type="submit"
        disabled={!stripe || loading}
      >
        Submit
      </Button>
      {errorMessage && <div className="pt-2 size-xs">{errorMessage}</div>}
    </form>
  );
}

CheckoutForm.propTypes = {
  price: PropTypes.number.isRequired,
  membership: PropTypes.string.isRequired,
};

export default CheckoutForm;
