import React, {
  useContext, useState, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Box, Button, CircularProgress,
} from '@mui/material';
import styled from '@emotion/styled';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { NotificationContext } from '../../contexts/Notification';
import { TextInput } from '../../components/muiCustomized/TextInput';
import { UserContext } from '../../contexts/User';
import { ChipSelectSearch } from '../../components/muiCustomized/ChipSelectSearchable';

const TextMD = styled.p`
    color: var(--dark-1, #0E1E40);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`;

function EditTeamModal({
  closeModal,
  onTeamEdited,
  name,
  managers,
  members,
  teamId,
}) {
  const [formData, setFormData] = useState({
    name, managers, members,
  });
  const [workspaceMembers, setWorkspaceMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const { displayNotification } = useContext(NotificationContext);
  const { activeWorkspace } = useContext(UserContext);

  const handleEditTeam = async () => {
    setIsUpdating(true);
    const { success } = await sendRequest({
      method: 'put',
      url: API.WORKSPACE.updateTeam(activeWorkspace.id, teamId),
      data: formData,
    });
    if (success) {
      setTimeout(() => {
        displayNotification({
          message: 'Team has been updated successfully.',
          severity: 'success',
        });
        onTeamEdited();
        closeModal();
      }, 500);
    } else {
      displayNotification({
        message: 'There was an issue updating team.',
        severity: 'error',
      });
    }
    setIsUpdating(false);
  };

  const handleFormChanged = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelectAllManagers = () => {
    setFormData({
      ...formData,
      managers: workspaceMembers.filter((member) => !formData.members.some((m) => m.id === member.id)),
    });
  };

  const handleSelectAllMembers = () => {
    setFormData({
      ...formData,
      members: workspaceMembers.filter((member) => !formData.managers.some((m) => m.id === member.id)),
    });
  };

  const fetchMembers = async () => {
    setIsLoading(true);
    const { success, data } = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.members(activeWorkspace.id),
      params: {
        pageSize: 0,
      },
    });
    if (success) {
      const filteredMembers = data.members
        .filter(({ haveJoined }) => haveJoined)
        .map((item) => ({
          id: item.id,
          name: `${item.user.firstName} ${item.user.lastName}`,
        }));
      filteredMembers.sort((item1, item2) => {
        if (item1.name > item2.name) return 1;
        if (item1.name < item2.name) return -1;
        return 0;
      });
      setWorkspaceMembers(filteredMembers);
    } else {
      displayNotification({
        message: 'There was an issue fetching members in this workspace. Please try again',
        severity: 'error',
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isChanged = useMemo(() => {
    const isManagerRemoved = managers.some((manager) => !formData.managers.some((m) => m.id === manager.id));
    const isMemberRemoved = members.some((member) => !formData.members.some((m) => m.id === member.id));
    return name !== formData.name
        || (isManagerRemoved || managers.length !== formData.managers.length)
        || (isMemberRemoved || members.length !== formData.members.length);
  }, [formData, managers, members, name]);

  return (
    <Modal open onClose={closeModal} className="flex-row align-c jstfy-c create-team-modal">
      <Box style={{
        width: 462,
        padding: 32,
        background: '#fff',
        borderRadius: 8,
        maxHeight: 768,
        overflowY: 'auto',
      }}
      >
        <div className="modal-body p-0" style={{ minHeight: 200 }}>
          <TextMD className="mb-3">
            Edit Team
          </TextMD>
          {isLoading && (
            <div className="position-relative">
              <div className="edit-modal loader-container">
                <CircularProgress />
              </div>
            </div>
          )}
          {!isLoading && (
            <>
              <TextInput
                id="name"
                name="name"
                value={formData.name}
                label="Edit Team Name"
                onChange={handleFormChanged}
                variant="outlined"
                sx={{
                  background: '#F9F9F9',
                  '& .MuiInputBase-input': {
                    border: '1.5px solid',
                    borderColor: 'var(--sds-input-border)',
                  },
                }}
              />
              <ChipSelectSearch
                id="managers"
                label="Edit Manager"
                placeholder="Edit Manager"
                selectedMembers={
                    workspaceMembers.filter((member) => formData.managers.some((m) => m.id === member.id))
                }
                selectAllBtn
                handleChange={handleFormChanged}
                handleSelectAll={handleSelectAllManagers}
                members={
                    workspaceMembers.filter((member) => !formData.members.some((m) => m.id === member.id))
                }
              />
              <ChipSelectSearch
                id="members"
                label="Edit Team Members"
                placeholder="Edit Team Members"
                selectedMembers={
                    workspaceMembers.filter((member) => formData.members.some((m) => m.id === member.id))
                }
                selectAllBtn
                handleSelectAll={handleSelectAllMembers}
                handleChange={handleFormChanged}
                members={
                    workspaceMembers.filter((member) => !formData.managers.some((m) => m.id === member.id))
                }
              />
            </>
          )}
        </div>

        <div className="modal-footer justify-content-end border-0 p-0 mt-2">
          <Button
            sx={{
              marginRight: 2,
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              padding: '12px 16px 12px 16px',
            }}
            variant="outlined"
            onClick={closeModal}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            sx={{
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              padding: '12px 16px 12px 16px',
              backgroundColor: '#429256',
              '&:hover': { backgroundColor: '#429256' },
            }}
            variant="contained"
            disabled={!isChanged
              || isUpdating
              || !(formData.name && formData.managers.length && formData.members.length)}
            onClick={handleEditTeam}
          >
            {isUpdating ? 'Updating...' : 'Save Changes'}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

EditTeamModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onTeamEdited: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  managers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired),
  members: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired),
  teamId: PropTypes.number.isRequired,
};

export default EditTeamModal;
