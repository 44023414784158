import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import Features from './Features';
import { UserContext } from '../../../contexts/User';

function Overview({ setTab }) {
  const { activeWorkspace } = useContext(UserContext);
  return (
    <div>
      <h5 className="fw-semibold mb-3">
        Your workspace is on the
        {' '}
        { activeWorkspace.membership !== 'None' ? activeWorkspace.membership : 'Free' }
        {' '}
        Plan
      </h5>
      <p className="mb-3">
        Upgrade to the
        {' '}
        <strong>Basic, Team, Professional</strong>
        {' '}
        or
        {' '}
        <strong>Enterprise</strong>
        {' '}
        plan for unlimited access to features, including:
      </p>
      <Features />
      <Link to={{
        state: {
          selectedTab: 1,
        },
      }}
      >
        <Button
          sx={{
            marginLeft: 'auto',
            font: 'Inter',
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: '500',
            textTransform: 'none',
            borderRadius: '4px',
            padding: '7px 29px',
            color: '#FFFFFF',
            backgroundColor: '#53A551',
            '&:hover': {
              backgroundColor: '#53A551',
            },
          }}
          variant="link"
          color="primary"
          component="span"
          className="mt-3"
          onClick={() => setTab(1)}
        >
          Upgrade Your Workspace
        </Button>
      </Link>
    </div>
  );
}

Overview.propTypes = {
  setTab: PropTypes.func,
};

export default Overview;
